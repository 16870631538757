import React from "react"
import PropTypes from "prop-types"

import styles from "./style.module.scss"

const Split = (props) => (
	
    <div className={ `
	    ${styles.container} 
	    ${ props.small && styles.containerSmall}
	    ${ props.vertical && styles.containerVertical}
	    ${ props.mobFull && styles.containerMobFull}` }>
		{props.children}
    </div>
  
)

export default Split